<template>
  <div>
    <b-card class="mb-1">
      <b-card-title class="mb-1">
        Reporte de asistencia
      </b-card-title>
      <b-card-sub-title class="mb-2">
        Seleccione el intervalo de fechas para calcular el reporte
      </b-card-sub-title>
      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <label for="example-datepicker">Fecha Inicio</label>
          <b-form-datepicker
            v-model="fechaInicio"
            class="mb-1"
            @input="refreshTabla"
          />
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <label for="example-datepicker">Fecha Fin</label>
          <b-form-datepicker
            v-model="fechaFin"
            class="mb-1"
            @input="refreshTabla"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div
        class="text-center bg-light-primary rounded"
        style="padding:10px 0px; "
      >
        TOTAL : <span class=""><strong> {{ reporteGeneralDesayuno[0] + reporteGeneralDesayuno[1] }} becarios</strong></span>
      </div>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <donut-chart
            :array-asistidos="reporteGeneralDesayuno"
            :nombre-comida="'Desayuno'"
          />
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <donut-chart
            :array-asistidos="reporteGeneralAlmuerzo"
            :nombre-comida="'Almuerzo'"
          />
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <donut-chart
            :array-asistidos="reporteGeneralCena"
            :nombre-comida="'Cena'"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row class="mb-1">
        <b-col cols="12">
          <span class="float-right">
            <p>
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Descargar"
                variant="primary"
              >
                <b-dropdown-item @click="descargarPdf">
                  Descargar PDF
                </b-dropdown-item>
                <b-dropdown-item @click="descargarExcel">
                  Descargar Excel
                </b-dropdown-item>

              </b-dropdown>
              <b-overlay
                :show="showOverlay"
                no-wrap
                variant="ligth"
              />
            </p>

          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="7"
          class=""
        >
          <label>Mostrar</label>
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="per-page-selector d-inline-block mx-50"
            style="width:auto"
            @change="actualizar_to_from"
          />
          <label>registros</label>
        </b-col>

        <b-col
          cols="5"
          class="mb-1"
        >
          <b-form-group
            label=""
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar..."
                size="sm"
              />

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  variant="success"
                  size="sm"
                  @click="filter = ''"
                >
                  Limpiar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

        </b-col>

      </b-row>

      <b-table
        :items="becarios"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        head-variant="light"
        class="position-relative"
        show-empty
        hover
        bordered
        responsive
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        @filtered="onFiltered"
      >

        <template #thead-top>
          <b-tr>
            <b-th
              colspan="5"
              class="text-center"
            >
              <span class="text-primary">Becarios </span>
            </b-th>
            <b-th
              class="text-center bg-light-primary"
              colspan="2"
              variant="secondary"
            >
              <span class="text-primary">Desayuno</span>
            </b-th>
            <b-th
              class="text-center bg-light-warning"
              variant="primary"
              colspan="2"
            >
              <span class="text-warning">Almuerzo</span>
            </b-th>
            <b-th
              class="text-center bg-light-danger"
              colspan="2"
              variant="danger"
            >
              <span class="text-danger">Cena</span>
            </b-th>
          </b-tr>
        </template>
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>
        <template #cell(dni)="row">
          {{ row.item.persona.numero_documento }} ({{ row.item.persona.documento.nombre }})
        </template>
        <template #cell(becario)="row">
          {{ row.item.persona.apellidos }} {{ row.item.persona.nombres }}
        </template>
        <template #cell(escuela)="row">
          {{ row.item.persona.escuela.nombre }}
        </template>
        <template #cell(etnia)="row">
          {{ row.item.persona.etnia }}
        </template>
        <template #cell(d_asistio)="row">
          {{ row.item.desayuno.asistio }}
        </template>
        <template #cell(d_no_asistio)="row">
          {{ row.item.desayuno.no_asistio }}
        </template>
        <template #cell(a_asistio)="row">
          {{ row.item.almuerzo.asistio }}
        </template>
        <template #cell(a_no_asistio)="row">
          {{ row.item.almuerzo.no_asistio }}
        </template>
        <template #cell(c_asistio)="row">
          {{ row.item.cena.asistio }}
        </template>
        <template #cell(c_no_asistio)="row">
          {{ row.item.cena.no_asistio }}
        </template>

      </b-table>
      <b-row
        cols="12"
        class="d-flex justify-content-between my-1 mb-md-0"
      >
        <b-col cols="6">
          <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
        </b-col>
        <b-col cols="4">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
            @input="actualizar_to_from"
          />
        </b-col>

      </b-row>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BOverlay, BRow, BCol, BFormDatepicker, BCardTitle, BCardSubTitle, BTr, BTh, BDropdown, BDropdownItem,
  BFormSelect, BButton, BFormGroup, BFormInput, BInputGroup, BPagination, BTable, BInputGroupAppend,
} from 'bootstrap-vue'
import moment from 'moment'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import reporteStoreModule from './reporteStoreModule'
import DonutChart from './DonutChart.vue'
import apexChartData from './apexChartData'

export default {
  components: {
    BTr,
    BTh,
    BCard,
    BFormDatepicker,
    BCardTitle,
    BCardSubTitle,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BPagination,
    BTable,
    BInputGroupAppend,
    BOverlay,
    DonutChart,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showOverlay: false,

      apexChartData,
      reporteGeneralDesayuno: [0],
      reporteGeneralAlmuerzo: [0],
      reporteGeneralCena: [0],
      hoy: moment().format('YYYY-MM-DD'),
      fechaInicio: '2022-08-02',
      fechaFin: '2022-08-02',

      becarios: [],
      cursoTallerUpdate: {},
      tallerGlobal_nombre: '',
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 300, 500],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      totalRows: 0,
      to: 0,
      from: 0,
      actionTaller: false,
      fields: [
        { key: 'index', label: 'N°', sortable: true },
        { key: 'dni', label: 'N° Doc', sortable: true },
        { key: 'becario', label: 'BECARIO', sortable: true },
        { key: 'escuela', label: 'ESCUELA', sortable: true },
        { key: 'etnia', label: 'ETNIA', sortable: true },
        { key: 'd_asistio', label: 'A', sortable: true },
        { key: 'd_no_asistio', label: 'NA', sortable: true },
        { key: 'a_asistio', label: 'A', sortable: true },
        { key: 'a_no_asistio', label: 'NA', sortable: true },
        { key: 'c_asistio', label: 'A', sortable: true },
        { key: 'c_no_asistio', label: 'NA', sortable: true },
      ],
    }
  },
  created() {
    this.fechaInicio = this.hoy
    this.fechaFin = this.hoy
    this.refreshTabla()
  },
  methods: {
    async refreshTabla() {
      const DATA = {
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
      }
      await store.dispatch('reportes-comedor-store-module/getReporte', DATA).then(response => {
        this.becarios = response.data.becarios
        this.totalRows = response.data.becarios.length

        this.reporteGeneralDesayuno = [response.data.general.desayuno.asistio, response.data.general.desayuno.no_asistio]
        if (response.data.general.desayuno.asistio === 0 && response.data.general.desayuno.no_asistio === 0) {
          this.reporteGeneralDesayuno = [0]
        }
        this.reporteGeneralAlmuerzo = [response.data.general.almuerzo.asistio, response.data.general.almuerzo.no_asistio]
        if (parseInt(response.data.general.almuerzo.asistio, 10) === 0 && parseInt(response.data.general.almuerzo.no_asistio, 10) === 0) {
          this.reporteGeneralAlmuerzo = [0]
        }
        this.reporteGeneralCena = [response.data.general.cena.asistio, response.data.general.cena.no_asistio]
        if (response.data.general.cena.asistio === 0 && response.data.general.cena.no_asistio === 0) {
          this.reporteGeneralCena = [0]
        }

        this.actualizar_to_from()
      })
    },
    actualizar_to_from() {
      const { totalRows } = this
      if (totalRows === 0) {
        this.from = 0
        this.to = 0
      } else
      if (this.currentPage === 1) {
        this.from = 1
        if (totalRows <= this.perPage) {
          this.to = totalRows
        } else {
          this.to = this.perPage
        }
      } else {
        this.from = ((this.currentPage - 1) * this.perPage) + 1
        if ((this.currentPage * this.perPage) <= totalRows) {
          this.to = this.currentPage * this.perPage
        } else {
          this.to = totalRows
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.actualizar_to_from()
    },
    async descargarPdf() {
      const DATA = {
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
        hoy: this.hoy,
      }
      this.showOverlay = true
      await store.dispatch('reportes-comedor-store-module/getReportePdf', DATA).then(() => {
        this.showOverlay = false
      })
    },
    async descargarExcel() {
      const DATA = {
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
        hoy: this.hoy,
      }
      this.showOverlay = true
      await store.dispatch('reportes-comedor-store-module/getReporteExcel', DATA).then(() => {
        this.showOverlay = false
      })
    },
  },
  setup() {
    const REPORTE_CALCULAR_APP_STORE_MODULE_NAME = 'reportes-comedor-store-module'

    // Register module
    if (!store.hasModule(REPORTE_CALCULAR_APP_STORE_MODULE_NAME)) store.registerModule(REPORTE_CALCULAR_APP_STORE_MODULE_NAME, reporteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTE_CALCULAR_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORTE_CALCULAR_APP_STORE_MODULE_NAME)
    })

    return {
      // resolveTallerStatusVariant,
    }
  },
}
</script>

<style>
</style>
