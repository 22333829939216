<template>
  <div>
    <b-card class="text-center">
      <b-card-title class="mb-1 ">
        <strong> {{ nombreComida }}</strong>
      </b-card-title>
      <div>
        <strong class="text-success">Asistieron: </strong> <span style="font-size: 16px;">{{ arrayAsistidos[0] }}</span> <br>
        <strong class="text-danger">No asistieron: </strong> <span style="font-size: 16px;">{{ arrayAsistidos[1] }}</span> <br>
      </div>
      <vue-apex-charts
        type="donut"
        height="300"
        :options="apexChartData.donutChart.chartOptions"
        :series="arrayAsistidos"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChartData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
  },
  props: {
    arrayAsistidos: {
      type: Array,
      default: () => [],
    },
    nombreComida: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      apexChartData,

    }
  },
}
</script>
