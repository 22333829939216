import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getReporte(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/comedor/reportes/asistencia', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getReportePdf(ctx, data) {
      return axios({
        url: '/servicio-social/beca/comedor/reportes_pdf/asistencia',
        method: 'GET',
        params: { fecha_inicio: data.fecha_inicio, fecha_fin: data.fecha_fin },
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          // link.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(response.data)}`)

          // link.style.display = 'none'
          link.href = url
          link.setAttribute('download', `Reporte-comedor-asistencia ${data.hoy}.pdf`)
          // link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {

        })
    },
    getReporteExcel(ctx, data) {
      return axios({
        url: '/servicio-social/beca/comedor/reportes_excel/asistencia',
        method: 'GET',
        params: { fecha_inicio: data.fecha_inicio, fecha_fin: data.fecha_fin },
        responseType: 'blob', // important
      })
        .then(response => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', `Reporte-comedor-asistencia ${data.hoy}.xlsx`) // any other extension
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(() => {

        })
    },

  },
}
